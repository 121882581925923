import { WorkspaceNotSetType } from '@squaredup/constants';
import { Node as GraphNode } from '@squaredup/graph';
import { StatusBlockDataItem } from 'pages/status/ui/blocks/StatusBlock';
import { useStatusConfig } from 'pages/status/utils/useStatusConfig';
import { useTenant } from 'queries/hooks/useTenant';
import { useWorkspacesWithHealthRollup } from 'queries/hooks/useWorkspacesWithHealthRollup';
import { graphQueryKeys } from 'queries/queryKeys/graphQueryKeys';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetchConnectedWorkspaceData } from './fetchConnectedWorkspaceData';
import { NodesAndEdges } from './types';

type QueryReturnType = NodesAndEdges | undefined;
type QuerySelectReturnType = QueryReturnType;

export const useGlobalMapData = (
    queryOptions?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => {
    const { data: tenant } = useTenant();

    const { data: nodesData, isLoading: isNodesLoading } = useQuery(
        graphQueryKeys.global,
        async () => fetchConnectedWorkspaceData(tenant!),
        {
            enabled: Boolean(tenant),
            ...queryOptions
        }
    );

    const { data: workspacesData, isLoading: isWorkspacesLoading } = useWorkspacesWithHealthRollup({
        select: (d): (StatusBlockDataItem & { workspaceId: string; type: string; tags: string[] })[] =>
            d.map(({ state, displayName: name, id, data: { properties } }) => ({
                state,
                name,
                id,
                link: `/workspace/${id}`,
                workspaceId: id,
                type: properties?.type ?? '',
                tags: properties?.tags ?? []
            })),
        refetchInterval: undefined
    });

    const { data: config } = useStatusConfig();

    const filteredWorkspaceIds = useMemo(() => {
        if (!workspacesData || !config) {
            return [];
        }

        const { workspaceTypes, tags: workspaceTags, filter } = config;

        return workspacesData
            .filter(
                ({ type }) =>
                    workspaceTypes.length === 0 ||
                    workspaceTypes.includes(type) ||
                    (type === '' && workspaceTypes.includes(WorkspaceNotSetType))
            )
            .filter(({ tags }) => workspaceTags.length === 0 || workspaceTags.some((t) => tags.includes(t)))
            .filter(({ state }) => filter.length === 0 || filter.includes(state))
            .map(({ id }) => id);
    }, [config, workspacesData]);

    const filteredData = useMemo(() => {
        if (!nodesData) {
            return undefined;
        }

        const leafNodes = nodesData.nodes.filter(
            ({ sourceId }) => !sourceId || filteredWorkspaceIds.includes(sourceId[0])
        );

        const nodeMap: Record<string, GraphNode> = {};

        const checkedParents = new Set<string>();
        const checkedChildren = new Set<string>();

        const includeNodes = (nodes: GraphNode[], type: 'parents' | 'children') => {
            for (const node of nodes) {
                if (type === 'parents' && checkedParents.has(node.id)) {
                    continue;
                } else if (type === 'children' && checkedChildren.has(node.id)) {
                    continue;
                }

                if (type === 'parents') {
                    checkedParents.add(node.id);
                } else {
                    checkedChildren.add(node.id);
                }

                nodeMap[node.sourceId?.[0] || ''] = node;

                const newNodes = nodesData.edges
                    .filter(({ inV, outV }) => (type === 'parents' ? inV === node.id : outV === node.id))
                    .map(
                        ({ outV, inV }) => nodesData.nodes.find(({ id }) => id === (type === 'parents' ? outV : inV))!
                    );

                includeNodes(newNodes, type);
            }
        };

        includeNodes(leafNodes, 'parents');
        if (config?.viewSettings.downstream) {
            includeNodes(
                leafNodes.filter(({ sourceId }) => Boolean(sourceId)),
                'children'
            );
        }

        return {
            nodes: Object.values(nodeMap).sort((a, b) => a.id.localeCompare(b.id)),
            edges: nodesData.edges.sort((a, b) => a.inV.localeCompare(b.inV) || a.outV.localeCompare(b.outV))
        };
    }, [nodesData, filteredWorkspaceIds, config?.viewSettings.downstream]);

    return {
        isLoading: isNodesLoading || isWorkspacesLoading,
        data: filteredData
    };
};
